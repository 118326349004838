<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="accent" dark flat dense>
          <v-toolbar-title>Blanket Agreement Record</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn dark small text to="/sales/lease-agreement">
            <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- editor -->
          <editor
            :templatesData="templatesData"
            :currencies="currencies"
            :initial="dataItem"
            @data="save"
          ></editor>
          <!-- end -->

          <v-row>
            <v-col cols="9"></v-col>
            <v-col cols="3">
              <v-autocomplete
                style="margin-top: -80px;"
                @change="copyDocTo(docId)"
                color="primary"
                v-model="docId"
                :items="copyDocs"
                item-text="name"
                item-value="id"
                label="Copy To"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- snackbar -->
      <snackbar ref="snackbar"></snackbar>
    </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor
  },
  computed: {
    ...mapGetters(["user"])
  },
  data: () => ({
    dataItem: {},
    currencies: [],
    templatesData: [],
    docId: null,
    copyDocs: [
      { name: "A/R Invoice", id: 13 },
      { name: "Offer Letter", id: 15 },
      { name: "A/R Downpayment Invoice", id: 16 }
      ]
  }),
  watch: {
    "$route.params.id": {
      handler: "getById",
      immediate: true
    }
  },
  methods: {
    copyDocTo(id) {
      const copyToDoc = id;
      console.log(copyToDoc);
      // const BaseType = 17;
      // const docId = this.dataItem.id;
      // if(copyToDoc == 13) {
      //   this.$router.push(`/ar-invoice/copy/${BaseType}/${docId}`);
      // }
    },
    getById(val) {
      //   const self = this;
      this.$store
        .dispatch("get", `/blanketagreement/${val}`)
        .then(res => {
          console.log(res, "rs");
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      console.log(data, "data");
      //   const url = "/blanketagreement";
      //   const self = this;
      //   this.$store
      //     .dispatch("post", { url, data })
      //     .then(() => {
      //       // self.$refs.snackbar.show("Item group created", "green");
      //       self.$router.push("/sales/lease-agreement");
      //     })
      //     .catch(err => {
      //       console.log(err, "err");
      //       // this.$refs.snackbar.show(err, "red");
      //     });
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then(res => {
          self.currencies = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getTemplates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/recurringtransactiontemplates`)
        .then(res => {
          self.templatesData = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getCurrencies();
    this.getTemplates();
  }
};
</script>